import gql from 'graphql-tag';
import { DocumentNode } from 'graphql';
import {
  IdentificationType,
  Handover,
  handoverQuery,
  getHandoverMock,
  Preparation,
  MarketAdaptionStates
} from '~app/apollo/queries/getHandoverExtended';
import { BookingStatuses, NoteType } from '~app/enums';

export const UPDATE_HANDOVER = gql`
  mutation UpdateHandover($input: UpdateHandoverRequest!) {
    updatedHandover: updateHandover(input: $input) {
      ${handoverQuery(false)}
    }
  }
`;

export interface UpdateHandoverResponse {
  updatedHandover: Handover;
}

interface NoteInput {
  id: string;
  type?: NoteType;
  text?: string;
  created: string;
  addedBy: string;
  title?: string;
}

export interface UpdateHandoverRequestData {
  assignedToUserId?: string | null;
  odometer?: string;
  stateOfCharge?: number;
  identificationNumber?: string;
  identificationType?: IdentificationType;
  preparations?: Array<Preparation>;
  notes?: Array<NoteInput>;
  bookingStatus?: BookingStatuses;
  deliveryAcceptanceComment?: string;
  manuallySetDeliveryDate?: string;
  marketAdaptionState?: MarketAdaptionStates;
  insurancePolicyNumber?: string;
  feedback?: {
    hasThumbUp?: boolean;
    comment?: string;
    userId?: string;
  };
  handoverPreparationInProgress?: boolean;
}

export interface UpdateHandoverRequest {
  input: {
    orderNumber: string;
  } & UpdateHandoverRequestData;
}

export const getUpdateHandoverApolloMock = (
  orderNumber: string,
  request: UpdateHandoverRequestData,
  result: Partial<Handover> = {}
): {
  request: { query: DocumentNode; variables: UpdateHandoverRequest };
  result: { data: UpdateHandoverResponse };
} => ({
  request: {
    query: UPDATE_HANDOVER,
    variables: {
      input: {
        orderNumber: orderNumber,
        ...request
      }
    }
  },
  result: {
    data: {
      updatedHandover: getHandoverMock(orderNumber, result)
    }
  }
});
