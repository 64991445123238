import React, { useEffect } from 'react';
import DocumentsContext from './context';
import useDocuments from '~hooks/useDocuments';
import getRuntimeConfig from '~root/src/config';
import { countryToLangMapper } from '~helpers/marketHelper';

type Props = {
  orderNumber: string;
  userLanguage: string;
  children: React.ReactNode;
  marketId?: string | undefined | null;
  batchId?: string | undefined;
};

const DocumentsContextProvider = (props: Props) => {
  const config = getRuntimeConfig();

  const {
    documents,
    fetchUploadableDocuments,
    fetchDocuments: refetch,
    fetchDocumentNameTranslations,
    uploadableDocuments,
    documentNameTranslations,
    fetchAllDocumentLinks,
    fetchDocumentFile,
    isLoading,
    isLoadingDocumentsDone,
    fetchDocumentLink,
    isFleetPurchaseOrderAvailable,
    documentCurrentlyBeingFetched
  } = useDocuments();

  useEffect(() => {
    if (config.build.deployRegion !== 'china') {
      refetch(props.orderNumber, props.batchId);
      if (props.marketId) {
        fetchUploadableDocuments(countryToLangMapper(props.marketId));
      }
    }
  }, []);

  useEffect(() => {
    if (config.build.deployRegion !== 'china') {
      fetchDocumentNameTranslations(props.userLanguage);
    }
  }, [props.userLanguage]);

  const providerValue = {
    documents,
    fetchUploadableDocuments,
    refetch,
    fetchDocumentNameTranslations,
    uploadableDocuments,
    documentNameTranslations,
    fetchAllDocumentLinks,
    fetchDocumentFile,
    isLoading,
    isLoadingDocumentsDone,
    fetchDocumentLink,
    isFleetPurchaseOrderAvailable,
    documentCurrentlyBeingFetched
  };

  return <DocumentsContext.Provider {...props} value={providerValue} />;
};

export { DocumentsContext };
export default DocumentsContextProvider;
