import { Label, List, Orientations, Spacer, Spacings } from '@polestar/component-warehouse-react';
import React from 'react';
import { displayNumberText, displayText } from '~helpers/stringHelper';
import useDatoState from '~hooks/useDatoState';
import * as Styled from './styles';
import { ExtraPackage, HandoverTableData } from '~apollo/queries/getHandoverExtended';
import { HeadingSmall } from '~components/Shared/styles';
import DataChip from '~components/Shared/DataChip';
import { getExtraStatus } from '~helpers/extrasHelper';
import TextWithLoading from '~components/Shared/TextWithLoading';

export const ExtraPackageCard = ({
  handover,
  extra,
  name,
  parts,
  orientation,
  loading
}: {
  handover: HandoverTableData;
  extra: ExtraPackage;
  name: string | undefined | null;
  parts: string;
  orientation: Orientations;
  loading?: boolean;
}) => {
  const { text } = useDatoState();

  return (
    <Styled.ExtraWrapper orientation={orientation}>
      <div>
        <HeadingSmall>{displayText(name)}</HeadingSmall>
        <Spacer spacing={Spacings.medium} />
        <DataChip cell={getExtraStatus(handover, extra)} expandCell={true} />
        <Spacer spacing={Spacings.medium} />

        <List spacing={Spacings.medium}>
          <div>
            <Label>{text('AccessoryNr')}</Label>
            <TextWithLoading text={displayText(extra.partNumber)} loading={loading} />
          </div>
          <div>
            <Label>{text('Parts')}</Label>
            <TextWithLoading text={parts} loading={loading} />
          </div>
          <div>
            <Label>{text('Quantity')}</Label>
            <TextWithLoading
              text={displayNumberText(extra.quantity).toString()}
              loading={loading}
            />
          </div>
        </List>
      </div>
    </Styled.ExtraWrapper>
  );
};
