import { MARKET_USER, Role } from '~apollo/queries/getRoles';

export const isLimitedUser = (
  role: Role,
  markets: Array<string> = [],
  locations: Array<string> = []
) =>
  role.order < MARKET_USER &&
  (markets?.length ?? 0) > 0 &&
  markets?.every(id => id === 'CA' || id === 'US') &&
  !locations.includes('CALAV0017') &&
  !locations.includes('CALAV0019') &&
  !locations.includes('CALAV0014');
