import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import {
  UpdateUserAppDataResponse,
  UPDATE_USER_APP_DATA,
  UpdateUserAppDataRequest
} from '~app/apollo/mutations/updateUserAppData';
import useAuthState from '~app/hooks/useAuthState';
import UpdatesDialog from '~app/components/Shared/UpdatesDialog';
import { GetUpdatesResponse, GET_UPDATES } from '~root/src/app/apollo/queries/getUpdates';
import { Update } from '~root/src/app/apollo/queries/getUpdate';
import gtmHelper from '~helpers/gtmHelper';
import { isLimitedUser } from '~root/src/app/helpers/userHelper';

const UpdateDialogArea = () => {
  const { user, setLoggedInUser, userMarkets, userLocations } = useAuthState();
  const [releaseNoteBannerClosed, setReleaseNoteBannerClosed] = useState(false);
  const [updatesToShow, setUpdatesToShow] = useState<Array<Update>>([]);

  const { data: updatesData } = useQuery<GetUpdatesResponse>(GET_UPDATES, {
    context: { dato: true }
  });

  const [updateUserAppData, { data: updateUserData }] = useMutation<
    UpdateUserAppDataResponse,
    UpdateUserAppDataRequest
  >(UPDATE_USER_APP_DATA, { fetchPolicy: 'network-only' });

  useEffect(() => {
    if (
      updateUserData !== undefined &&
      updateUserData !== null &&
      user.appData?.lastReadReleaseNoteId !==
        updateUserData.updatedUser.appData?.lastReadReleaseNoteId
    ) {
      setLoggedInUser({
        ...user,
        ...{ appData: updateUserData.updatedUser.appData }
      });
    }
  }, [updateUserData, setLoggedInUser, user]);

  useEffect(() => {
    if (updatesData) {
      setUpdatesToShow(
        updatesData.allHandoverUpdates.filter(
          update => update.date === updatesData.allHandoverUpdates[0].date
        )
      );
    }
  }, [updatesData]);

  const pushEvent = useCallback(() => {
    if (updatesToShow.length > 0) {
      gtmHelper.pushWhatsNewVisible({ headLines: updatesToShow.map(updates => updates.headline) });
    }
  }, [updatesToShow]);

  const showReleaseNoteBanner = useMemo(() => {
    if (!updatesData || isLimitedUser(user.role, userMarkets, userLocations)) {
      return false;
    }

    const updatesExists =
      updatesData.allHandoverUpdates && updatesData.allHandoverUpdates.length > 0;
    const unreadUpdates =
      !user.appData?.lastReadReleaseNoteId || !user.appData?.lastReadReleaseNoteId.includes('-');
    const newUpdates =
      new Date(user.appData?.lastReadReleaseNoteId ?? '') <
      new Date(updatesData.allHandoverUpdates[0].date);

    if ((updatesExists && unreadUpdates) || newUpdates) {
      pushEvent();
      return true;
    }

    return false;
  }, [
    pushEvent,
    updatesData,
    user.appData?.lastReadReleaseNoteId,
    user.role,
    userMarkets,
    userLocations
  ]);

  return (
    <div data-testid="banner-area">
      {showReleaseNoteBanner && !releaseNoteBannerClosed && (
        <UpdatesDialog
          updates={updatesToShow}
          onClose={() => {
            updateUserAppData({
              variables: {
                input: {
                  userProfileId: user.id,
                  lastReadReleaseNoteId: updatesToShow[0].date
                }
              }
            });
            setReleaseNoteBannerClosed(true);
          }}
        />
      )}
    </div>
  );
};

export default UpdateDialogArea;
