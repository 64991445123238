import {
  Alignments,
  CheckboxField,
  Colors,
  Heading,
  LayoutIndexes,
  List,
  PrimaryButton,
  Spacer,
  Spacings,
  Text,
  Transitions,
  useReducedMotion,
  useSnackbar
} from '@polestar/component-warehouse-react';
import React, { useCallback, useMemo, useState } from 'react';
import { HandoverTableData } from '~apollo/queries/getHandoverExtended';
import OrderHeaderInfo from '~components/Shared/Notes/OrderHeaderInfo';
import useDatoState from '~root/src/app/hooks/useDatoState';
import { InternalFontSizes } from '@polestar/component-warehouse-react/internal';
import { ColoredParagraph, DrawerFooter } from '../styles';
import { displayText, isNonEmptyString } from '~helpers/stringHelper';
import { LightDivider } from '~helpers/styles';
import {
  getAdditionalUpgradeStatus,
  getCellFromAdditionalUpgradeStatus,
  getCodesIncludedInVehicleRequirement,
  isUpgradeItemOrdered
} from '~helpers/additionalUpgradesHelper';
import { useMutation } from '@apollo/client';
import {
  UPDATE_HANDOVER,
  UpdateHandoverRequest,
  UpdateHandoverResponse
} from '~apollo/mutations/updateHandover';
import { updatePreparation } from '~helpers/handoverHelper';
import useAuthState from '~hooks/useAuthState';
import { PreparationsIds } from '~enums/VehicleRequirements';
import { cloneDeep } from 'lodash';
import { AnimatePresence } from 'framer-motion';
import useTableColumns from '~hooks/useTableColumns';
import { TableCells } from '~enums/OrderTable';
import DataChip from '~components/Shared/DataChip';

const AdditionalUpgradesDrawerContent = ({
  handover,
  onClose
}: {
  handover: HandoverTableData;
  onClose: () => void;
}) => {
  const { text } = useDatoState();
  const { user, userLanguage } = useAuthState();
  const isReducedMotion = useReducedMotion();
  const { addSnackbar } = useSnackbar();
  const { renderCell } = useTableColumns(userLanguage);

  const [additionalUpgrades] = useState(
    handover.orderData?.additionalUpgrades
      ?.map(additional => additional.code)
      .filter(code => isNonEmptyString(code)) ?? []
  );

  const [requiredAdditionalUpgrades] = useState<Array<string>>(
    getCodesIncludedInVehicleRequirement(handover)
  );

  const [orderedAdditionalUpgrades, setOrderedAdditionalUpgrades] = useState<Array<string>>(
    requiredAdditionalUpgrades.filter(code => isUpgradeItemOrdered(code, handover.preparations))
  );

  const [initialState] = useState(orderedAdditionalUpgrades.sort().toString());

  const showSaveFooter = useMemo(() => {
    const currentSelection = orderedAdditionalUpgrades.sort().toString();
    return currentSelection !== initialState;
  }, [orderedAdditionalUpgrades, initialState]);

  const [updateHandoverMutation, { loading: isUpdating }] = useMutation<
    UpdateHandoverResponse,
    UpdateHandoverRequest
  >(UPDATE_HANDOVER, {
    onCompleted: () => {
      addSnackbar({
        content: text('SingleOptionsUpdateSucessfull{VIN}').replace('{VIN}', handover.vin ?? ''),
        error: false,
        closeOnClick: true
      });
      onClose();
    }
  });

  const handleSave = useCallback(() => {
    let preparationsCopy = cloneDeep(handover.preparations);
    requiredAdditionalUpgrades.forEach(option => {
      preparationsCopy = updatePreparation(
        handover.preparations ?? [],
        user.id,
        user.name,
        `${PreparationsIds.ADDITIONAL_UPGRADES_ORDERED_PREFIX}${option}`,
        orderedAdditionalUpgrades.includes(option)
      );
    });

    updateHandoverMutation({
      variables: {
        input: {
          orderNumber: handover.orderNumber,
          preparations: preparationsCopy ?? []
        }
      }
    });
  }, [
    handover.preparations,
    user.id,
    user.name,
    updateHandoverMutation,
    orderedAdditionalUpgrades
  ]);

  return (
    <>
      <Heading>{text('SingleOptions')}</Heading>
      <Spacer />

      {renderCell({ name: TableCells.SINGLE_OPTIONS_STATUS }, true, handover)}
      <Spacer />
      <OrderHeaderInfo handover={handover} />
      {additionalUpgrades.length > 0 && (
        <>
          <List separator={<LightDivider spacing={Spacings.large} />}>
            {additionalUpgrades.map(code => (
              <div key={`additional-upgrade-${code}`}>
                <Text
                  style={{ fontSize: InternalFontSizes.smallHeading }}
                  data-testid={`additional-upgrade-${code}`}
                >
                  {text(`ADDITIONAL_UPGRADE_${code}`)}
                </Text>
                <Spacer />
                <DataChip
                  cell={getCellFromAdditionalUpgradeStatus(
                    getAdditionalUpgradeStatus(code ?? '', handover) ?? ''
                  )}
                  expandCell={true}
                />
                <Spacer />
                <ColoredParagraph color={Colors.stormGrey}>{text('Code')}</ColoredParagraph>
                <Text>{displayText(code)}</Text>
                {requiredAdditionalUpgrades.includes(code ?? '') && (
                  <>
                    <Spacer />
                    <ColoredParagraph color={Colors.stormGrey}>{text('Ordered')}</ColoredParagraph>
                    <CheckboxField
                      checked={orderedAdditionalUpgrades.includes(code ?? '')}
                      label={text(`ADDITIONAL_UPGRADE_${code}`)}
                      data-testid={`required-additional-upgrade-${code}`}
                      onChange={value =>
                        value
                          ? setOrderedAdditionalUpgrades([...orderedAdditionalUpgrades, code ?? ''])
                          : setOrderedAdditionalUpgrades(
                              orderedAdditionalUpgrades.filter(item => item !== code)
                            )
                      }
                    />
                  </>
                )}
              </div>
            ))}
          </List>
        </>
      )}

      <AnimatePresence>
        {showSaveFooter && (
          <DrawerFooter
            animate={{ y: 0 }}
            initial={{ y: 100 }}
            exit={{ y: 100 }}
            transition={{ ...Transitions.reduced, disabled: isReducedMotion }}
            style={{ zIndex: LayoutIndexes.modal + 1 }}
            data-testid="save-changes-footer"
          >
            <PrimaryButton
              loading={isUpdating}
              iconPosition={Alignments.right}
              onClick={() => handleSave()}
            >
              {text('SaveSelected')}
            </PrimaryButton>
          </DrawerFooter>
        )}
      </AnimatePresence>
    </>
  );
};

export default AdditionalUpgradesDrawerContent;
