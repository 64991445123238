import { useContext } from 'react';
import { OrderTableContext } from '~app/contexts/OrderTable';

const useOrderTableState = () => {
  const context = useContext(OrderTableContext);
  if (context === undefined) {
    throw new Error('Order table context not defined');
  }
  return context;
};

export default useOrderTableState;
