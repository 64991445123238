import gql from 'graphql-tag';
import { DocumentNode } from 'graphql';
import { DocumentType } from '~app/hooks/useDocuments';

export const GET_ALL_DOCUMENT_NAMES = gql`
  query allHandoverDocumentItems($language: SiteLocale!) {
    names: allHandoverDocumentItems(first: 100) {
      systemName
      humanReadableName(locale: $language)
    }
    fallbackNames: allHandoverDocumentItems(first: 100) {
      systemName
      humanReadableName(locale: en)
    }
  }
`;

export interface GetAllDocumentNamesParams {
  language: string;
}

export interface GetAllDocumentNamesResponse {
  names: Array<{
    systemName: DocumentType;
    humanReadableName: string;
  }>;
  fallbackNames: Array<{
    systemName: DocumentType;
    humanReadableName: string;
  }>;
}

export const getAllDocumentNamesApolloMock = (
  input: GetAllDocumentNamesParams,
  result: GetAllDocumentNamesResponse = { names: [], fallbackNames: [] }
): {
  request: { query: DocumentNode; variables: GetAllDocumentNamesParams };
  result: { data: GetAllDocumentNamesResponse | undefined };
} => ({
  request: {
    query: GET_ALL_DOCUMENT_NAMES,
    variables: input
  },
  result: {
    data: result
  }
});
