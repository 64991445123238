import { Route } from 'react-router-dom';
import {
  orderType,
  ALL,
  SPECIALIST,
  MARKET_USER,
  POLESTAR_HQ,
  MANAGER
} from '~app/apollo/queries/getRoles';

/**
 * Array of route definition object
 * Each object will be map to react-router-dom <Route /> component and respect the following shape
 *
 * - name (string) : name of the route, internal reference used to generate a path for a given route
 * - path (string) : uri fragment, can contain variables in react-router-dom's fashion (e.g. /:locale/something/:id)
 * - exact (boolean) : wether to stricly match path or allow sub-routes (@see react-router-dom)
 * - view (string) : name of the view component to render
 * - private (boolean) : wether the route should be accessible publicly or not
 * - sitemap (object) : public sitemap.xml informations (if set to undefined, will be ignored by the sitemap and not indexed)
 */

export type RouteName =
  | 'home'
  | 'users'
  | 'orders'
  | 'handover'
  | 'locations'
  | 'moment'
  | 'support'
  | 'manageorders'
  | 'setlocation'
  | 'search'
  | 'export'
  | 'releasenote'
  | 'calendar'
  | 'delivery-details'
  | 'whatsnew'
  | 'update'
  | 'send-wheels'
  | 'manage-wheels'
  | 'manage-vin-messages'
  | 'publish-vin-data'
  | 'feedback'
  | 'about'
  | 'comments'
  | 'performance'
  | 'booking'
  | 'vehicle-preparation'
  | 'handover-preparation'
  | 'wheels-handling'
  | 'handover-activity'
  | 'bulk-orders'
  | 'operating-guidelines'
  | 'handover-portal-guidelines'
  | 'batch'
  | 'holidays'
  | 'create-ticket';

export enum Path {
  HOME = `/`,
  ORDERS = '/orders',
  USERS = '/users',
  HANDOVER = '/handover/:id/:subcomponent?',
  LOCATIONS = '/locations',
  SET_LOCATIONS = '/setlocation/:id?',
  MOMENT = '/moment/:id',
  SUPPORT = '/support',
  FEEDBACK = '/feedback',
  MANAGE_ORDERS = '/manage-orders',
  SEARCH = '/search',
  EXPORT = '/export',
  RELEASE_NOTE = '/release-note/:id',
  WHATS_NEW = '/whats-new',
  UPDATE = '/update/:date/:id',
  CALENDAR = '/calendar/:id?',
  MANAGE_VIN_MESSAGE = '/manage-vin-messages',
  PUBLISH_VIN_DATA = '/publish-vin-data/:id?',
  ABOUT_PERFORMANCE = '/performance/about',
  ABOUT_DELIVERY_PLANING = '/about-delivery-planning',
  COMMENTS = '/performance/comments',
  PERFORMANCE = '/performance',
  BULK_ORDERS = '/bulk-orders/:id?',
  OPERATING_GUIDELINES = '/operating-guidelines',
  HANDOVER_PORTAL_GUIDELINES = '/handover-portal-guidelines',
  BATCH = '/batch/:id',
  HOLIDAYS = '/holidays',
  CREATE_TICKET = '/handover/:id/create-ticket'
}

export enum HandoverSubcomponents {
  BOOKING = 'booking',
  VEHICLE_PREPARATION = 'vehicle-preparation',
  WHEELS_HANDLING = 'wheels-handling',
  REGISTRATION = 'registration',
  HANDOVER_PREPARATION = 'handover-preparation',
  HANDOVER_ACTIVITY = 'handover-activity',
  DELIVERY_DETAILS = 'delivery-details'
}

export type RouteDefinition = {
  name: RouteName;
  path: Path;
  view: string;
  private: boolean;
  sitemap: object;
  roleLevel: orderType;
};

export const Routes = {
  HOME: {
    name: 'home',
    path: Path.HOME,
    view: 'LandingView',
    private: false,
    sitemap: {
      frequency: 'WEEKLY',
      priority: 1
    },
    roleLevel: ALL
  } as RouteDefinition,
  ORDERS: {
    name: 'orders',
    path: Path.ORDERS,
    view: 'OrdersView',
    private: false,
    sitemap: {
      frequency: 'WEEKLY',
      priority: 1
    },
    roleLevel: ALL
  } as RouteDefinition,
  USERS: {
    name: 'users',
    path: Path.USERS,
    view: 'UsersView',
    private: false,
    sitemap: {
      frequency: 'WEEKLY',
      priority: 1
    },
    roleLevel: SPECIALIST
  } as RouteDefinition,
  HANDOVER: {
    name: 'handover',
    path: Path.HANDOVER,
    view: 'HandoverView',
    private: false,
    sitemap: {
      frequency: 'WEEKLY',
      priority: 1
    },
    roleLevel: ALL
  } as RouteDefinition,
  LOCATIONS: {
    name: 'locations',
    path: Path.LOCATIONS,
    view: 'LocationsView',
    private: false,
    sitemap: {
      frequency: 'WEEKLY',
      priority: 1
    },
    roleLevel: MARKET_USER
  } as RouteDefinition,
  SET_LOCATIONS: {
    name: 'setlocation',
    path: Path.SET_LOCATIONS,
    view: 'SetLocationView',
    private: false,
    sitemap: {
      frequency: 'WEEKLY',
      priority: 1
    },
    roleLevel: ALL
  } as RouteDefinition,
  MOMENT: {
    name: 'moment',
    path: Path.MOMENT,
    view: 'HandoverMomentView',
    private: false,
    sitemap: {
      frequency: 'WEEKLY',
      priority: 1
    },
    roleLevel: SPECIALIST
  } as RouteDefinition,
  SUPPORT: {
    name: 'support',
    path: Path.SUPPORT,
    view: 'SupportView',
    private: false,
    sitemap: {
      frequency: 'WEEKLY',
      priority: 1
    },
    roleLevel: SPECIALIST
  } as RouteDefinition,
  FEEDBACK: {
    name: 'feedback',
    path: Path.FEEDBACK,
    view: 'FeedbackView',
    private: false,
    sitemap: {
      frequency: 'WEEKLY',
      priority: 1
    },
    roleLevel: SPECIALIST
  } as RouteDefinition,
  MANAGE_ORDERS: {
    name: 'manageorders',
    path: Path.MANAGE_ORDERS,
    view: 'ManageOrdersView',
    private: false,
    sitemap: {
      frequency: 'WEEKLY',
      priority: 1
    },
    roleLevel: SPECIALIST
  } as RouteDefinition,
  SEARCH: {
    name: 'search',
    path: Path.SEARCH,
    view: 'SearchView',
    private: false,
    sitemap: {
      frequency: 'WEEKLY',
      priority: 1
    },
    roleLevel: SPECIALIST
  } as RouteDefinition,
  EXPORT: {
    name: 'export',
    path: Path.EXPORT,
    view: 'ExportView',
    private: false,
    sitemap: {
      frequency: 'WEEKLY',
      priority: 1
    },
    roleLevel: SPECIALIST
  } as RouteDefinition,
  RELEASE_NOTE: {
    name: 'releasenote',
    path: Path.RELEASE_NOTE,
    view: 'ReleaseNoteView',
    private: false,
    sitemap: {
      frequency: 'WEEKLY',
      priority: 1
    },
    roleLevel: SPECIALIST
  } as RouteDefinition,
  WHATS_NEW: {
    name: 'whatsnew',
    path: Path.WHATS_NEW,
    view: 'WhatsNewView',
    private: false,
    sitemap: {
      frequency: 'WEEKLY',
      priority: 1
    },
    roleLevel: SPECIALIST
  } as RouteDefinition,
  UPDATE: {
    name: 'update',
    path: Path.UPDATE,
    view: 'UpdateView',
    private: false,
    sitemap: {
      frequency: 'WEEKLY',
      priority: 1
    },
    roleLevel: SPECIALIST
  } as RouteDefinition,
  CALENDAR: {
    name: 'calendar',
    path: Path.CALENDAR,
    view: 'CalendarView',
    private: false,
    sitemap: {
      frequency: 'WEEKLY',
      priority: 1
    },
    roleLevel: SPECIALIST
  } as RouteDefinition,
  MANAGE_VIN_MESSAGE: {
    name: 'manage-vin-messages',
    path: Path.MANAGE_VIN_MESSAGE,
    view: 'ManageVinMessagesView',
    private: false,
    sitemap: {
      frequency: 'WEEKLY',
      priority: 1
    },
    roleLevel: MARKET_USER
  } as RouteDefinition,
  PUBLISH_VIN_DATA: {
    name: 'publish-vin-data',
    path: Path.PUBLISH_VIN_DATA,
    view: 'PublishVinSpecificDataView',
    private: false,
    sitemap: {
      frequency: 'WEEKLY',
      priority: 1
    },
    roleLevel: MARKET_USER
  } as RouteDefinition,
  ABOUT_PERFORMANCE: {
    name: 'about',
    path: Path.ABOUT_PERFORMANCE,
    view: 'AboutPerformanceView',
    private: false,
    sitemap: {
      frequency: 'WEEKLY',
      priority: 1
    },
    roleLevel: SPECIALIST
  } as RouteDefinition,
  COMMENTS: {
    name: 'comments',
    path: Path.COMMENTS,
    view: 'CommentsView',
    private: false,
    sitemap: {
      frequency: 'WEEKLY',
      priority: 1
    },
    roleLevel: SPECIALIST
  } as RouteDefinition,
  PERFORMANCE: {
    name: 'performance',
    path: Path.PERFORMANCE,
    view: 'PerformanceView',
    private: false,
    sitemap: {
      frequency: 'WEEKLY',
      priority: 1
    },
    roleLevel: SPECIALIST
  } as RouteDefinition,
  BULK_ORDERS: {
    name: 'bulk-orders',
    path: Path.BULK_ORDERS,
    view: 'BulkView',
    private: false,
    sitemap: {
      frequency: 'WEEKLY',
      priority: 1
    },
    roleLevel: SPECIALIST
  } as RouteDefinition,
  OPERATING_GUIDELINES: {
    name: 'operating-guidelines',
    path: Path.OPERATING_GUIDELINES,
    view: 'OperationalGuidelinesView',
    private: false,
    sitemap: {
      frequency: 'WEEKLY',
      priority: 1
    },
    roleLevel: POLESTAR_HQ
  } as RouteDefinition,
  HANDOVER_PORTAL_GUIDELINES: {
    name: 'handover-portal-guidelines',
    path: Path.HANDOVER_PORTAL_GUIDELINES,
    view: 'OperationalGuidelinesView',
    private: false,
    sitemap: {
      frequency: 'WEEKLY',
      priority: 1
    },
    roleLevel: POLESTAR_HQ
  } as RouteDefinition,
  BATCH: {
    name: 'batch',
    path: Path.BATCH,
    view: 'BatchView',
    private: false,
    sitemap: {
      frequency: 'WEEKLY',
      priority: 1
    },
    roleLevel: SPECIALIST
  } as RouteDefinition,
  HOLIDAYS: {
    name: 'holidays',
    path: Path.HOLIDAYS,
    view: 'HolidayView',
    private: false,
    sitemap: {
      frequency: 'WEEKLY',
      priority: 1
    },
    roleLevel: MANAGER
  } as RouteDefinition,
  CREATE_TICKET: {
    name: 'create-ticket',
    path: Path.CREATE_TICKET,
    view: 'CreateTicketView',
    private: false,
    sitemap: {
      frequency: 'WEEKLY',
      priority: 1
    },
    roleLevel: SPECIALIST
  } as RouteDefinition
};

export default <RouteDefinition[]>[
  Routes.HOME,
  Routes.ORDERS,
  Routes.USERS,
  Routes.HANDOVER,
  Routes.LOCATIONS,
  Routes.SET_LOCATIONS,
  Routes.MOMENT,
  Routes.SUPPORT,
  Routes.FEEDBACK,
  Routes.MANAGE_ORDERS,
  Routes.SEARCH,
  Routes.EXPORT,
  Routes.RELEASE_NOTE,
  Routes.WHATS_NEW,
  Routes.UPDATE,
  Routes.CALENDAR,
  Routes.MANAGE_VIN_MESSAGE,
  Routes.PUBLISH_VIN_DATA,
  Routes.ABOUT_PERFORMANCE,
  Routes.COMMENTS,
  Routes.PERFORMANCE,
  Routes.BULK_ORDERS,
  Routes.OPERATING_GUIDELINES,
  Routes.HANDOVER_PORTAL_GUIDELINES,
  Routes.BATCH,
  Routes.HOLIDAYS,
  Routes.CREATE_TICKET
];
